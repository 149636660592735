import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				FAQ | DriveWell - Ваш путь к уверенности!
			</title>
			<meta name={"description"} content={"Ваш путеводитель по всем вопросам обучения вождению"} />
			<meta property={"og:title"} content={"FAQ | DriveWell - Ваш путь к уверенности!"} />
			<meta property={"og:description"} content={"Ваш путеводитель по всем вопросам обучения вождению"} />
			<meta property={"og:image"} content={"https://oionslace.com/images/bg.jpg"} />
			<link rel={"shortcut icon"} href={"https://oionslace.com/images/i.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://oionslace.com/images/i.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://oionslace.com/images/i.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://oionslace.com/images/i.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://oionslace.com/images/i.png"} />
			<meta name={"msapplication-TileImage"} content={"https://oionslace.com/images/i.png"} />
			<meta name={"msapplication-TileColor"} content={"https://oionslace.com/images/i.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="60px 0" sm-padding="40px 0" color="--dark">
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="33.333%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h1" font="--headline2" margin="0 0 8px 0">
							Часто задаваемые вопросы (FAQ)
						</Text>
						<Text as="p" font="--lead" margin="0" color="--greyD2">
							Добро пожаловать на страницу часто задаваемых вопросов DriveWell, где мы постараемся ответить на ваши вопросы и предоставить вам всю необходимую информацию о наших услугах по обучению вождению. Здесь приведены некоторые часто задаваемые вопросы:
						</Text>
					</Box>
				</Box>
				<Box padding="16px 16px 16px 16px" width="66.66%" md-width="100%">
					<Box display="flex" flex-direction="row" flex-wrap="wrap">
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Как записаться на курс в DriveWell?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									Чтобы записаться на любой из наших курсов, Вы можете связаться с нами по телефону или электронной почте. Наш дружелюбный коллектив проведет вас через весь процесс регистрации.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Какова стоимость ваших курсов вождения?
								</Text>
							</Box>
							<Text as="p" font="--base" margin="12px 0" color="--greyD2">
								Стоимость наших курсов зависит от выбранного Вами курса. Пожалуйста, обратитесь к странице "Курсы", чтобы получить информацию о ценах.
							</Text>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Предлагаете ли вы какие-либо скидки или акции?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									Да, мы периодически предлагаем специальные акции и скидки. Следите за текущими предложениями на нашем сайте или свяжитесь с нами.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Сколько времени требуется для прохождения курса?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									Продолжительность наших курсов зависит от программы. Как правило, курс может длиться от нескольких недель до нескольких месяцев. Более подробная информация предоставляется в процессе регистрации.
								</Text>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="0px 0 50px" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				Надеемся, что на ваши вопросы мы ответили, но если у вас остались вопросы или вы просто хотите получить дополнительную информацию, пожалуйста, обращайтесь к нам. Наша специальная группа поддержки готова помочь вам в решении любых вопросов и проблем.
			</Text>
			<Button
				font="--lead"
				margin="20px"
				type="link"
				text-decoration-line="initial"
				href="/contact-us"
			>
				Контакты
			</Button>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});